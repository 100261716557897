import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetJobList = ({
  limit,
  sortOrder,
  sortField,
  current,
  previous,
  first,
  last,
  enterprise,
  machineId,
  recycler,
  retailer
}) => {
  return useQuery({
    queryKey: ['getJobData', limit, sortOrder, sortField, current, previous, first, last, enterprise, machineId, recycler, retailer],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.jobs}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          enterprise: enterprise,
          machineId: machineId,
          recycler: recycler,
          retailer: retailer
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///find By Id

export const useGetJobId = ({ id }) => {
  return useQuery({
    queryKey: ['getJobById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.jobs}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// job details update api
export const useGetJobUpdateList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(
          `${endpoints.jobs}/${data?.jobId}/drives/${data?.driveId}`,

          {
            finalMake: data?.finalMake,
            finalModel: data?.finalModel,
            finalSerial: data?.finalSerial
          }
        )
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'success';
            setToast(toastConfig, error?.response?.data?.error);
          }
          return res;
        })

        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getJobById'], type: 'active' });

      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

////Get all Jobs while clicking machine  Id

// export const useGetJobMachineList = ({ enterprise, machineId }) => {
//   return useQuery({
//     queryKey: ['getJobMachineListData', enterprise, machineId],
//     queryFn: async () => {
//       return await axiosInstance
//         .post(`${endpoints.jobs}`, {
//           enterprise: enterprise,
//           machineId: machineId
//         })

//         .then((res) => {
//           if (res?.status == 200) {
//             return res?.data.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//     },
//     onSuccess: ({ data }) => {
//       return data;
//     }
//   });
// };

//machineId Job List
