import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL

  // headers: {
  //   'Content-Type': 'application/json'
  // }
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (window.localStorage) {
      const token = localStorage.getItem('userJwtToken');

      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default axiosInstance;
