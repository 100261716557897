import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect } from 'react';
const UpdateRetailerOrganizationForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,
  setFieldValue,

  retailerDetails,
  isLoadingOrgUpdate,
  btnName
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (retailerDetails) {
      setFieldValue('RetailerName', retailerDetails?.name ? retailerDetails?.name : '');
      setFieldValue('RetailerWeb', retailerDetails?.website ? retailerDetails?.website : '');
    }
  }, [retailerDetails, setFieldValue]);
  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Organization Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.RetailerName && errors.RetailerName)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-name"> Retailer Name *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-name"
                    type="text"
                    value={values.RetailerName}
                    name="RetailerName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Retailer Name"
                    placeholder="e.g. Example Pvt Ltd"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.RetailerName && errors.RetailerName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-name"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.RetailerName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.RetailerWeb && errors.RetailerWeb)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-website"> Retailer Website Address</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-website"
                    type="text"
                    value={values.RetailerWeb}
                    name="RetailerWeb"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Retailer Web"
                    inputProps={{}}
                    autoComplete="off"
                    placeholder="e.g. https://www.fosub.org"
                  />
                  {touched.RetailerWeb && errors.RetailerWeb && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.RetailerWeb}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingOrgUpdate}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingOrgUpdate ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName}`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default UpdateRetailerOrganizationForm;
