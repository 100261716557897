import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';
// import { useParams } from 'react-router';

export const useRecyclerAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.recylers}/create`, data);
  return res;
};
//List Api

export const useGetRecycleList = ({ limit, sortOrder, sortField, current, previous, first, last }) => {
  return useQuery({
    queryKey: ['getRecycleData', limit, sortOrder, sortField, current, previous, first, last],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.recylers}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
        })
        // .get(`${endpoints.recylers}/all?previous=0&current=1&sortField=id&sortOrder=asc&limit=5`)

        // previous=0&current=1&sortField=id&sortOrder=asc&limit=5
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///
export const useGetRecyclerAllList = () => {
  return useQuery({
    queryKey: 'getRecycleAllData',
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.recylers}`, {
          isConfirmed: true,
          enabled: true
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// hooks call for superadmin invoice to recycle
export const useGetRecyclerAllListInvoice = () => {
  return useQuery({
    queryKey: 'getRecycleAllDataInvoice',
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.recylers}`, {
          isConfirmed: true
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// export const useGetEnterpriseAllList = () => {
//   return useQuery({
//     queryKey: ['getEnterpriseData'],
//     queryFn: async () => {
//       return await axiosInstance
//         .get(`${endpoints.enterprises}`)
//         .then((res) => {
//           if (res?.status == 200) {
//             return res?.data.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//     },
//     onSuccess: ({ data }) => {
//       return data;
//     }
//   });
// };

//for Editing
export const useGetRecycleId = ({ id }) => {
  return useQuery({
    queryKey: ['getRecycleDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.recylers}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Submitted Edited List

export const UseGetRecycleEditedList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.recylers}/${data.id} `, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getRecycleDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getRecycleData');
      // queryClient.refetchQueries('getRecycleDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Recycler update status

export const useRecyclerStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.recylers}/status/${data?.id}`, { enabled: data?.enabled })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries('getRecycleData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/// Recycler User List

export const useGetRecyclerUserList = ({ limit, sortOrder, sortField, current, previous, type }) => {
  return useQuery({
    queryKey: ['getRecyclerUserData', limit, sortOrder, sortField, current, previous, type],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.users}/all?previous=${previous}&current=${current}&sortField=${sortField}&sortOrder=${sortOrder}&limit=${limit}&type=${type}`
        )

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///////after recycle location

export const useGetAllEnterpriseAfterRecycle = ({ recyclerId, locationId }) => {
  return useQuery({
    queryKey: ['getAllEnterpriseAfterRecycle', recyclerId, locationId],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.enterprises}`, {
          recycler: {
            recyclerId: recyclerId,
            locationId: locationId
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(recyclerId) && Boolean(locationId),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//

export const useGetAllRetailerAfterRecycle = ({ recyclerId, locationId }) => {
  return useQuery({
    queryKey: ['getAllRetailerAfterRecycle', recyclerId, locationId],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.retailers}`, {
          recycler: {
            recyclerId: recyclerId,
            locationId: locationId
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(recyclerId) && Boolean(locationId),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
