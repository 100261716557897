import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TicketForm from '../ticket-form';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useState } from 'react';
import { useEffect } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { useMutation } from '@tanstack/react-query';
import { useTicketReasonAdd } from 'hooks/useTicketReasonHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
const TicketAdd = () => {
  const [isLoading, setLoading] = useState(true);

  const { mutate: addTicketReason, isPending: isLoadingTicketreason } = useMutation({
    mutationFn: useTicketReasonAdd
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Ticket Reason"
            obj={{ title: 'All Ticket Reasons', title2: 'Add Ticket Reason', url: '/dashboard/ticket' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                reason: ''
              }}
              validationSchema={Yup.object().shape({
                reason: Yup.string().required('Reason is required')
              })}
              onSubmit={async (value, { resetForm }) => {
                const submitData = {
                  reason: value.reason.trim()
                };

                addTicketReason(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);

                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched }) => (
                <>
                  <TicketForm
                    btnName="Add"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    values={values}
                    isLoadingTicket={isLoadingTicketreason}
                    touched={touched}
                    id={''}
                  />
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default TicketAdd;
