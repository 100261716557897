import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';
import UpdateOrganizationForm from './form';
import MainCard from 'ui-component/cards/MainCard';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useOrganizationUpdate } from 'hooks/useOrganizationUpdateHooks';

import { useGetRecycleId } from 'hooks/useRecycleHooks';
// import { useGetRecycleId } from 'hooks/useRecycleHooks';
// import { useParams } from 'react-router-dom';

const UpdateOrganization = () => {
  const [isLoading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userData'));
  // console.log('🚀 ~ userData recycler id:', userData);

  const { data: recycleDetails } = useGetRecycleId({
    id: userData.recycler?.id
  });
  const { mutate: updatedOrg, isPending: isLoadingOrgUpdate } = useOrganizationUpdate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <>
        {isLoading ? (
          <>
            <FormView />
          </>
        ) : (
          <>
            <BreadcrumbsForPage name=" Organization" obj={{ title: ' Organization', title2: '', url: '' }} />
            <MainCard>
              <Formik
                initialValues={{
                  RecyclerName: '',
                  RecyclerWeb: '',
                  recycler_radius: '',
                  recycler_id: ''
                }}
                validationSchema={Yup.object().shape({
                  RecyclerName: Yup.string()
                    .max(50, 'Recycler Name must be at most 50 characters')
                    .required('Recycler Name is required')
                    .trim()
                    .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                    .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, 'Recycler Name should not be in any special character'),

                  RecyclerWeb: Yup.string().url('Please enter a valid Recycler Website Address'),
                  recycler_radius: Yup.number()
                    .positive('Recycler Service Radius must contain a positive number')

                    .when('areSeatsLimited', {
                      is: 'yes',
                      then: Yup.number().required().typeError('Recycler Service Radius must be a number'),
                      otherwise: Yup.number().notRequired().typeError('Recycler Service Radius must be a number')
                    })
                    .required('Recycler Service Radius is required')
                })}
                onSubmit={async (value) => {
                  const data = {
                    organizationId: userData?.recycler?.id,
                    type: userData?.organizationType,
                    details: {
                      name: value?.RecyclerName,
                      website: value?.RecyclerWeb,
                      serviceRadius: {
                        value: value?.recycler_radius
                      }
                    }
                  };
                  updatedOrg(data);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                  setValues,
                  setTouched
                }) => (
                  <UpdateOrganizationForm
                    btnName="Update Organization"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    setValues={setValues}
                    userData={userData}
                    recycleDetails={recycleDetails}
                    isLoadingOrgUpdate={isLoadingOrgUpdate}
                  />
                )}
              </Formik>
            </MainCard>
          </>
        )}
      </>
    </>
  );
};

export default UpdateOrganization;
