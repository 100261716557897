import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';

const MachineFilterForm = ({
  errors,
  //   handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  //   setFieldValue,
  // setTouched,
  resetForm,
  //   filterObj,
  handleCloseFilter,
  setFilterObj
  //   isExistingFilter
}) => {
  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^', '.'];
  return (
    <>
      <DialogContent className="choose_option_modal">
        <Grid container spacing={gridSpacing} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select_separate2">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={values?.status}
                onChange={handleChange}
                name="status"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select_separate2">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Machine Type
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <InputLabel>Machine Type</InputLabel>
              <Select
                label="Machine Type"
                value={values?.machineType}
                onChange={handleChange}
                name="machineType"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="Enterprise">Enterprise</MenuItem>
                <MenuItem value="Retailer">Retailer</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Machine Id
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <TextField
                label="Machine Id"
                variant="outlined"
                type="text"
                fullWidth
                name="machineId"
                value={values?.machineId}
                onChange={handleChange}
                error={Boolean(touched.machineId && errors.machineId)}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onKeyDown={(e) => {
                  exceptThisSymbols.includes(e.key) && e.preventDefault();
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              machineId: '',
              machineType: ''
            });
            handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <Grid item xs={12}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Apply filter
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </>
  );
};

export default MachineFilterForm;
