// assets

import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

// import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import dashboard from './dashboard';
// constant

const icons = {
  PrecisionManufacturingOutlinedIcon,

  // StorefrontOutlinedIcon
  LocationOnOutlinedIcon,
  GroupOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocalActivityIcon,
  ReceiptIcon,
  RequestQuoteIcon
};

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'machines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/machines'
    },
    {
      id: 'locations',
      title: 'Locations',
      type: 'item',
      icon: icons.LocationOnOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/locations'
    },
    {
      id: 'jobs',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/jobs'
    },
    {
      id: 'ticket',
      title: 'Tickets',
      type: 'item',
      icon: icons.LocalActivityIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/tickets'
    },
    {
      id: 'existingInvoice',
      title: 'Existing Invoices',
      type: 'item',
      icon: icons.ReceiptIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/existing/invoices'
    },
    {
      id: 'recyclerInvoice',
      title: 'Invoices',
      type: 'item',
      icon: icons.RequestQuoteIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler/invoices'
    }
  ]
};

const RecyclerPage = {
  items: [dashboard, pages]
};
export default RecyclerPage;
