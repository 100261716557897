// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import RetailerPage from 'menu-items/retailer.page';
import RecyclerPage from 'menu-items/recycler.page';
import EnterprisePage from 'menu-items/enterprise.page';
// import withOutC2MenuItems from 'menu-items/withOutC2Index';
// import { useDispatch } from 'react-redux';
// import { userLogin } from 'store/actions/userAction';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  // const userOrgId = JSON.parse(localStorage.getItem('userOrgId'));
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.organizationType === 'retailer') {
    const navItems = RetailerPage.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

    return <>{navItems}</>;
  } else if (userData?.organizationType === 'recycler') {
    const navItems = RecyclerPage.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    return <>{navItems}</>;
  } else if (userData?.organizationType === 'enterprise') {
    const navItems = EnterprisePage.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    return <>{navItems}</>;
  } else {
    const navItems = menuItem.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

    return <>{navItems}</>;
  }
};

export default MenuList;
