import { Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import EnterpriseUserTicketForm from '../enterprise-tickets-form';
import { useMutation } from '@tanstack/react-query';
import { useAddEnterpriseTicket } from 'hooks/useTickets';
import { setToast, toastConfig } from 'utils/commonUtil';
const EnterpriseUserTicketAdd = () => {
  const [isLoading, setLoading] = useState(true);
  const enterprise = JSON.parse(localStorage.getItem('userData'));
  const { mutate: addTicketEnterprise, isPending: isLoadingEnterpriseTicket } = useMutation({
    mutationFn: useAddEnterpriseTicket
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Create Tickets"
            obj={{ title: 'All Tickets', title2: 'Create Tickets', url: '/dashboard/enterprise/tickets' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                subject: '',

                reason: '',
                machine: '',
                notes: '',
                priority: '',
                documents: [],
                submit: null
              }}
              validationSchema={Yup.object().shape({
                subject: Yup.string().required('Please put your subject !'),
                reason: Yup.string().required('Please select a reason !'),
                machine: Yup.string().required('Please select a machine !'),
                priority: Yup.string().required('Please select a priority !')
                // notes: Yup.string().required('Notes is required')
              })}
              onSubmit={async (value, { resetForm }) => {
                const data = {
                  enterprise: enterprise?.enterprise?.id,
                  machine: value?.machine,
                  subject: value?.subject,
                  notes: value?.notes,
                  reason: value?.reason,
                  priority: value?.priority,
                  files: value?.documents
                };
                addTicketEnterprise(data, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);
                      // queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({
                errors,
                setFieldValue,
                isSubmitting,
                handleBlur,
                handleSubmit,
                handleChange,
                touched,
                values,
                setValues,
                setTouched,
                ticketDetails
              }) => (
                <EnterpriseUserTicketForm
                  btnName="Create"
                  errors={errors}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setValues={setValues}
                  setTouched={setTouched}
                  ticketDetails={ticketDetails}
                  isLoadingTicket={isLoadingEnterpriseTicket}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default EnterpriseUserTicketAdd;
