// assets
import { IconKey } from '@tabler/icons';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
//  import DomainAddSharpIcon from '@mui/icons-material/DomainAddSharpIcon';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
// constant

const icons = {
  IconKey,
  PrecisionManufacturingOutlinedIcon,
  BusinessOutlinedIcon,
  PeopleOutlineOutlinedIcon,
  PersonAddAltIcon,
  GroupAddOutlinedIcon,
  StorefrontOutlinedIcon,
  CorporateFareOutlinedIcon,
  LocationOnOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocalActivityIcon,
  RequestQuoteIcon,
  SettingsIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

/** FUTURE USE */
// const pages = {
//   id: 'pages',
//   title: 'Pages',
//   caption: 'Pages Caption',
//   type: 'group',
//   children: [
//     {
//       id: 'authentication',
//       title: 'Authentication',
//       type: 'collapse',
//       icon: icons.IconKey,

//       children: [
//         {
//           id: 'login3',
//           title: 'Login',
//           type: 'item',
//           url: '/pages/login/login3',
//           target: true
//         },
//         {
//           id: 'register3',
//           title: 'Register',
//           type: 'item',
//           url: '/pages/register/register3',
//           target: true
//         }
//       ]
//     }
//   ]
// };
/** FUTURE USE */

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'machines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/machines'
    },

    {
      id: 'recycler',
      title: 'Recycler Organizations',
      type: 'item',
      icon: icons.GroupAddOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/recycler'
    },
    {
      id: 'enterprise',
      title: 'Enterprise Organizations',
      type: 'item',
      icon: icons.CorporateFareOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/enterprise'
      // children:[
      //   {
      //     id:'enterprise-user',
      //     title:'User',
      //     type:'item',
      //     url:'/dashboard/enterprise/enterprise-user'
      //   }
      // ]
    },
    {
      id: 'authentication',
      title: 'Retailer Organizations',
      // type: 'collapse',
      type: 'item',
      breadcrumbs: true,
      icon: icons.StorefrontOutlinedIcon,
      url: '/dashboard/retailer'
      // children: [
      //   {
      //     id: 'new-retailer-list',
      //     title: 'Retailer Lists',
      //     type: 'item',
      //     url: '/dashboard/retailer'
      //     // target: true
      //   },
      //   {
      //     id: 'public-user',
      //     title: 'Public User',
      //     type: 'item',
      //     url: '/dashboard/retailer/public-user'
      //     // target: true
      //   }
      // ]
    },
    {
      id: 'location',
      title: 'Locations',
      type: 'item',
      icon: icons.LocationOnOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/location'
    },

    {
      id: 'job',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/job'
    },

    {
      id: 'ticket',
      title: 'Ticket Reasons',
      type: 'item',
      icon: icons.LocalActivityIcon,
      breadcrumbs: true,
      url: '/dashboard/ticket'
    },

    {
      id: 'invoices-super-admin',
      title: 'Invoices',
      type: 'item',
      icon: icons.RequestQuoteIcon,
      breadcrumbs: true,
      url: '/dashboard/invoices'
    }
    // {
    //   id: 'super-admin-settings',
    //   title: 'Settings',
    //   type: 'item',
    //   icon: icons.SettingsIcon,
    //   breadcrumbs: true,
    //   url: '/dashboard/settings'
    // }
  ]
};

export default pages;
