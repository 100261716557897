/* eslint-disable no-restricted-imports */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ErrorMessage, Field, FieldArray, Form } from 'formik';
import React from 'react';
import { useTheme } from '@emotion/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { useGetRecyclerOrgItemsByLocation } from 'hooks/useInvoiceHooks';
import { useEffect } from 'react';

import AnimateButton from 'ui-component/extended/AnimateButton';

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
// import { useGetEnterpriseTicketById } from 'hooks/useTickets';
import { useGetRecyclerTicketById } from 'hooks/useRecyclerUserHooks';
// import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// const StyledGridOverlay = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: '100%',
//   width: '100%',
//   margin: '0 auto',
//   '& .ant-empty-img-1': {
//     fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
//   },
//   '& .ant-empty-img-2': {
//     fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
//   },
//   '& .ant-empty-img-3': {
//     fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
//   },
//   '& .ant-empty-img-4': {
//     fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
//   },
//   '& .ant-empty-img-5': {
//     fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
//     fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
//   }
// }));

const TaxType = {
  PERCENTAGE: 'percentage',
  PRICE: 'price'
};

const DiscountType = {
  PERCENTAGE: 'percentage',
  PRICE: 'price'
};

const RetailerTable = ({ values, touched, errors, handleChange }) => {
  const theme = useTheme();

  const calculateSubtotal = (data) => {
    return data?.rowsRetailer?.reduce((total, row) => {
      const amount = parseFloat(row.amount) || 0;
      return row.creditDebit === 'Credit' ? total + amount : total - amount;
    }, 0);
  };

  const calculateTotal = (data) => {
    const subtotal = calculateSubtotal(data);
    const taxAmount = data?.taxType === TaxType.PERCENTAGE ? (data.tax / 100) * subtotal : parseFloat(data.tax) || 0;
    const discountAmount =
      data.discountType === DiscountType.PERCENTAGE ? (data.discount / 100) * subtotal : parseFloat(data.discount) || 0;
    const shippingAndHandlingCharge = data.shippingAndHandlingCharge || 0;
    // setFieldValue('totalValue', values);
    // setTotalValue(subtotal + taxAmount - discountAmount);
    return subtotal + taxAmount - discountAmount + shippingAndHandlingCharge;
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 700, marginTop: '20px!important' }}
        aria-label="spanning table"
        size="small"
        className="new-table-classs new-table-classs2 "
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ width: '80px' }}>
              Item
            </TableCell>
            <TableCell align="center" style={{ width: '800px' }}>
              Description *
            </TableCell>{' '}
            {/* Increased width for description */}
            <TableCell align="center" style={{ width: '150px' }}>
              Amount *
            </TableCell>
            <TableCell align="center" style={{ width: '90px' }}>
              Credit/Debit
            </TableCell>
            <TableCell style={{ width: '90px' }}></TableCell> {/* Reduced space for icons */}
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="rowsRetailer">
            {({ insert, remove }) => (
              <>
                {values.rowsRetailer.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.item}</TableCell>
                    <TableCell>
                      <Field
                        placeholder="Description"
                        name={`rowsRetailer.${index}.description`}
                        as={TextField}
                        multiline
                        rows={2} // Minimum number of rows
                        // maxRows={3} // Maximum number of rows
                        fullWidth
                        error={touched.rowsRetailer?.[index]?.description && !!errors.rowsRetailer?.[index]?.description}
                        helperText={<ErrorMessage name={`rowsRetailer.${index}.description`} />}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        placeholder="Amount"
                        name={`rowsRetailer.${index}.amount`}
                        type="number"
                        as={TextField}
                        fullWidth
                        error={touched.rowsRetailer?.[index]?.amount && !!errors.rowsRetailer?.[index]?.amount}
                        id="outlined-start-adornment"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        helperText={<ErrorMessage name={`rowsRetailer.${index}.amount`} />}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl
                        fullWidth
                        // sx={{ ...theme.typography.customInput }}
                        error={touched.rowsRetailer?.[index]?.creditDebit && !!errors.rowsRetailer?.[index]?.creditDebit}
                      >
                        <Field
                          name={`rowsRetailer.${index}.creditDebit`}
                          as={Select}
                          fullWidth
                          // className="credit_debit_select"
                          sx={{ ...theme.typography.customInput, textAlign: 'left' }}
                        >
                          <MenuItem value="Credit">Credit</MenuItem>
                          <MenuItem value="Debit">Debit</MenuItem>
                        </Field>
                        <FormHelperText>
                          <ErrorMessage name={`rowsRetailer.${index}.creditDebit`} />
                        </FormHelperText>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      {index === 0 ? (
                        // <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              insert(values.rowsRetailer.length, {
                                id: values.rowsRetailer.length + 1,
                                item: `${values.rowsRetailer.length + 1}`,
                                description: '',
                                amount: 0,
                                creditDebit: 'Credit'
                              })
                            }
                            style={{ padding: '5px' }} // Reduce icon button size
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton
                            disabled
                            color="secondary"
                            onClick={() => remove(index)}
                            style={{ padding: '5px' }} // Reduce icon button size
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : (
                        // </div>
                        <IconButton
                          // disabled={values?.rows[index]?.id !== values?.rows?.length}
                          color="secondary"
                          onClick={() => remove(index)}
                          style={{ padding: '5px', color: 'red' }} // Reduce icon button size
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </FieldArray>
        </TableBody>
      </Table>
      <div className="border-none-tablenew2">
        <div className="frm-flex-box">
          <div style={{ marginLeft: '10px' }}>
            <b>Subtotal</b>
          </div>

          <div style={{ marginLeft: '15px' }}>
            <b>${calculateSubtotal(values).toFixed(2)}</b>
          </div>
        </div>
        <div className="frm-flex-box">
          <b>Tax</b>
          <div style={{ marginLeft: '10px', width: '150px' }}>
            <TextField
              placeholder="Tax"
              name="tax"
              type="number"
              value={values.tax}
              onChange={handleChange}
              error={touched.tax && !!errors.tax}
              helperText={touched.tax && errors.tax}
              // fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{values.taxType === TaxType.PERCENTAGE ? '%' : '$'}</InputAdornment>
              }}
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <FormControl
              fullWidth
              sx={{ ...theme.typography.customInput, textAlign: 'left' }}
              // sx={{ ...theme.typography.customInput }}
            >
              {/* <InputLabel>Type</InputLabel> */}
              <Select name="taxType" value={values.taxType} onChange={handleChange} sx={{ ...theme.typography.customInput }}>
                <MenuItem value={TaxType.PERCENTAGE}>%</MenuItem>
                <MenuItem value={TaxType.PRICE}>$</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="frm-flex-box">
          <b>Discount</b>
          <div style={{ marginLeft: '10px', width: '150px' }}>
            <TextField
              placeholder="Discount"
              name="discount"
              type="number"
              value={values.discount}
              onChange={handleChange}
              error={touched.discount && !!errors.discount}
              helperText={touched.discount && errors.discount}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{values.discountType === DiscountType.PERCENTAGE ? '%' : '$'}</InputAdornment>
                )
              }}
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <FormControl
              fullWidth
              sx={{ ...theme.typography.customInput, textAlign: 'left' }}
              // sx={{ ...theme.typography.customInput }}
            >
              {/* <InputLabel>Type</InputLabel> */}
              <Select name="discountType" value={values.discountType} onChange={handleChange} sx={{ ...theme.typography.customInput }}>
                <MenuItem value={DiscountType.PERCENTAGE}>%</MenuItem>
                <MenuItem value={DiscountType.PRICE}>$</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="frm-flex-box">
          <b>Shipping & Handling Charge</b>
          <div style={{ marginLeft: '10px', width: '215px' }}>
            <TextField
              placeholder="Shipping and handling"
              name="shippingAndHandlingCharge"
              type="number"
              value={values.shippingAndHandlingCharge}
              onChange={handleChange}
              error={touched.shippingAndHandlingCharge && !!errors.shippingAndHandlingCharge}
              helperText={touched.shippingAndHandlingCharge && errors.shippingAndHandlingCharge}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>
              }}
            />
          </div>
        </div>
        <div
          className="frm-flex-box"
          style={{
            marginTop: '15px'
          }}
        >
          <div style={{ marginLeft: '10px' }}>
            <b> Total </b>
          </div>

          <div
            style={{
              marginLeft: '15px',
              color: '#6fd74b',
              fontWeight: 'bold'
            }}
          >
            ${calculateTotal(values).toFixed(2)}
          </div>
        </div>
      </div>

      <Table size="small" className="new-tableborder">
        <TableBody>
          <TableRow className="border-none-table">
            <TableCell rowSpan={4} style={{ width: '228px' }} />

            {/* <TableCell rowSpan={3} style={{ width: '300px' }} /> */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EnterpriseTable = ({ values, touched, errors, handleChange }) => {
  const theme = useTheme();

  const calculateSubtotal = (data) => {
    return data?.rowsEnterprise?.reduce((total, row) => {
      const amount = parseFloat(row.amount) || 0;

      return row.creditDebit === 'Credit' ? total + amount : total - amount;
    }, 0);
  };

  // Function to calculate the total value including tax, discount, and shipping charge
  const calculateTotal = (data) => {
    // Safeguard against invalid input types
    const subtotal = calculateSubtotal(data);

    // Convert tax, discount, and shippingAndHandlingCharge to numbers or default to 0 if not valid
    const taxAmount = data?.taxType === TaxType.PERCENTAGE ? (data.tax / 100) * subtotal : parseFloat(data.tax) || 0;
    const discountAmount =
      data.discountType === DiscountType.PERCENTAGE ? (data.discount / 100) * subtotal : parseFloat(data.discount) || 0;
    const shippingAndHandlingCharge = data.shippingAndHandlingCharge || 0;
    // setFieldValue('totalValue', values);
    // setTotalValue(subtotal + taxAmount - discountAmount);
    return subtotal + taxAmount - discountAmount + shippingAndHandlingCharge;
  };

  return (
    <TableContainer component={Paper}>
      <>
        <Table
          sx={{ minWidth: 700, marginTop: '20px!important' }}
          aria-label="spanning table"
          size="small"
          className="new-table-classs new-table-classs2 "
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: '80px' }}>
                Item
              </TableCell>
              <TableCell align="center" style={{ width: '800px' }}>
                Description *
              </TableCell>{' '}
              <TableCell align="center" style={{ width: '150' }}>
                Amount *
              </TableCell>{' '}
              <TableCell align="center" style={{ width: '90px' }}>
                Credit/Debit
              </TableCell>{' '}
              <TableCell style={{ width: '90px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="rowsEnterprise">
              {({ insert, remove }) => {
                return (
                  <>
                    {values.rowsEnterprise.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.item}</TableCell>
                        <TableCell>
                          <Field
                            placeholder="Description"
                            name={`rowsEnterprise.${index}.description`}
                            as={TextField}
                            multiline
                            rows={2} // Minimum number of rows
                            // maxRows={3} // Maximum number of rows
                            fullWidth
                            error={touched.rowsEnterprise?.[index]?.description && !!errors.rowsEnterprise?.[index]?.description}
                            helperText={<ErrorMessage name={`rowsEnterprise.${index}.description`} />}
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            placeholder="Amount"
                            name={`rowsEnterprise.${index}.amount`}
                            type="number"
                            disabled={row?.static}
                            as={TextField}
                            fullWidth
                            error={touched.rowsEnterprise?.[index]?.amount && !!errors.rowsEnterprise?.[index]?.amount}
                            id="outlined-start-adornment"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            helperText={<ErrorMessage name={`rowsEnterprise.${index}.amount`} />}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl
                            fullWidth
                            // sx={{ ...theme.typography.customInput }}
                            error={touched.rowsEnterprise?.[index]?.creditDebit && !!errors.rowsEnterprise?.[index]?.creditDebit}
                          >
                            <Field
                              name={`rowsEnterprise.${index}.creditDebit`}
                              as={Select}
                              fullWidth
                              // className="credit_debit_select"
                              sx={{ ...theme.typography.customInput, textAlign: 'left' }}
                            >
                              <MenuItem value="Credit">Credit</MenuItem>
                              <MenuItem value="Debit">Debit</MenuItem>
                            </Field>
                            <FormHelperText>
                              <ErrorMessage name={`rowsEnterprise.${index}.creditDebit`} />
                            </FormHelperText>
                          </FormControl>
                        </TableCell>
                        {/* <TableCell>$ {row?.amount ? Number(row?.amount).toFixed(2) : calculateRowTotal(row).toFixed(2)}</TableCell> */}

                        <TableCell>
                          {index === 0 ? (
                            // <div style={{ flexDirection: 'column', display: 'flex' }}>
                            <>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  insert(values.rowsEnterprise.length, {
                                    id: values.rowsEnterprise.length + 1,
                                    item: `${values.rowsEnterprise.length + 1}`,
                                    description: '',
                                    amount: 0,
                                    unitPrice: 0,
                                    quantity: 0,
                                    static: false
                                  })
                                }
                                style={{ padding: '5px' }} // Reduce icon button size
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton
                                disabled
                                color="secondary"
                                onClick={() => remove(index)}
                                style={{ padding: '5px' }} // Reduce icon button size
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : (
                            // </div>
                            <IconButton
                              disabled={row?.static}
                              color="secondary"
                              onClick={() => remove(index)}
                              style={{ padding: '5px', color: 'red' }} // Reduce icon button size
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                );
              }}
            </FieldArray>
          </TableBody>
        </Table>

        <div className="border-none-tablenew2">
          <div className="frm-flex-box">
            <div style={{ marginLeft: '10px' }}>
              <b>Subtotal</b>
            </div>

            <div style={{ marginLeft: '15px' }}>
              <b>${calculateSubtotal(values).toFixed(2)}</b>
            </div>
          </div>
          <div className="frm-flex-box">
            <b>Tax</b>
            <div style={{ marginLeft: '10px', width: '150px' }}>
              <TextField
                // disabled
                placeholder="tax"
                name="tax"
                type="number"
                value={values?.tax}
                // value={values.tax}
                onChange={handleChange}
                error={touched.tax && !!errors.tax}
                helperText={touched.tax && errors.tax}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{values.taxType === TaxType.PERCENTAGE ? '%' : '$'}</InputAdornment>
                }}
              ></TextField>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <FormControl
                fullWidth
                sx={{ ...theme.typography.customInput, textAlign: 'left' }}
                // sx={{ ...theme.typography.customInput }}
              >
                <Select name="taxType" value={values.taxType} onChange={handleChange} sx={{ ...theme.typography.customInput }}>
                  <MenuItem value={TaxType.PERCENTAGE}>%</MenuItem>
                  <MenuItem value={TaxType.PRICE}>$</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="frm-flex-box">
            <b>Discount</b>
            <div style={{ marginLeft: '10px', width: '150px' }}>
              <TextField
                placeholder="Discount"
                name="discount"
                type="number"
                value={values.discount}
                onChange={handleChange}
                error={touched.discount && !!errors.discount}
                helperText={touched.discount && errors.discount}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{values.discountType === DiscountType.PERCENTAGE ? '%' : '$'}</InputAdornment>
                  )
                }}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <FormControl
                fullWidth
                sx={{ ...theme.typography.customInput, textAlign: 'left' }}
                // sx={{ ...theme.typography.customInput }}
              >
                <Select name="discountType" value={values.discountType} onChange={handleChange} sx={{ ...theme.typography.customInput }}>
                  <MenuItem value={DiscountType.PERCENTAGE}>%</MenuItem>
                  <MenuItem value={DiscountType.PRICE}>$</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="frm-flex-box">
            <b>Shipping & handling</b>
            <div style={{ marginLeft: '10px', width: '215px' }}>
              <TextField
                placeholder="Shipping and handling"
                name="shippingAndHandlingCharge"
                type="number"
                value={values.shippingAndHandlingCharge}
                onChange={handleChange}
                error={touched.shippingAndHandlingCharge && !!errors.shippingAndHandlingCharge}
                helperText={touched.shippingAndHandlingCharge && errors.shippingAndHandlingCharge}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>
                }}
              />
            </div>
          </div>
          <div
            className="frm-flex-box"
            style={{
              marginTop: '15px'
            }}
          >
            <div style={{ marginLeft: '10px' }}>
              <b> Total </b>
            </div>

            <div
              style={{
                marginLeft: '15px',
                color: '#6fd74b',
                fontWeight: 'bold'
              }}
            >
              ${calculateTotal(values).toFixed(2)}
            </div>
          </div>
        </div>

        <Table size="small" className="new-tableborder">
          <TableBody>
            <TableRow className="border-none-table">
              <TableCell rowSpan={4} style={{ width: '228px' }} />

              <TableCell rowSpan={3} style={{ width: '300px' }} />
            </TableRow>
          </TableBody>
        </Table>
      </>
    </TableContainer>
  );
};
const RecyclerCreateForm = ({ errors, handleBlur, handleChange, touched, values, setFieldValue, btnName, isLoadingInvoice }) => {
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('userData'));

  let { id } = useParams();

  const { data: ticketDetails, isPending: isLoadingTicketDetails } = useGetRecyclerTicketById({
    id: id.slice(1)
  });
  // console.log('🚀 ~ RecyclerCreateForm ~ ticketDetails:', ticketDetails);

  const { data: organizationItemsDetails, isLoading: isLoadingOrganizationItemDetails } = useGetRecyclerOrgItemsByLocation({
    id: values?.organization_location,
    from: dayjs(values.fromMonth).startOf('month').format('YYYY-MM-DD'),
    to: dayjs(values.toMonth).endOf('month').format('YYYY-MM-DD'),
    groupBy: values?.groupByVal
  });

  useEffect(() => {
    if (ticketDetails?.retailer) {
      setFieldValue('orgName', ticketDetails?.retailer?.name ?? '');
      setFieldValue('organization_id', ticketDetails?.retailer?.id ?? '');
      setFieldValue(
        'orgMainPOCName',
        ticketDetails?.retailer?.mainPOC?.prefix +
          ' ' +
          ticketDetails?.retailer?.mainPOC?.firstName +
          ' ' +
          ticketDetails?.retailer?.mainPOC?.lastName ?? ''
      );
      setFieldValue('orgMainPOCEmail', ticketDetails?.retailer?.mainPOC?.email ?? '');
      setFieldValue('orgMainPOCPhone', ticketDetails?.retailer?.mainPOC?.phone ?? '');
      setFieldValue('organization_location_name', ticketDetails?.machine?.location?.name);
      setFieldValue('organization_location', ticketDetails?.machine?.location?.id);
      setFieldValue('organization_type', 'Retailer');
    } else if (ticketDetails?.enterprise) {
      setFieldValue('organization_id', ticketDetails?.enterprise?.id ?? '');
      setFieldValue('orgName', ticketDetails?.enterprise?.name ?? '');
      setFieldValue(
        'orgMainPOCName',
        ticketDetails?.enterprise?.mainPOC?.prefix +
          ' ' +
          ticketDetails?.enterprise?.mainPOC?.firstName +
          ' ' +
          ticketDetails?.enterprise?.mainPOC?.lastName ?? ''
      );
      setFieldValue('orgMainPOCEmail', ticketDetails?.enterprise?.mainPOC?.email ?? '');
      setFieldValue('orgMainPOCPhone', ticketDetails?.enterprise?.mainPOC?.phone ?? '');
      setFieldValue('organization_location_name', ticketDetails?.machine?.location?.name);
      setFieldValue('organization_location', ticketDetails?.machine?.location?.id);
      setFieldValue('organization_type', 'Enterprise');
    }
  }, [ticketDetails, setFieldValue]);

  const handleDateChange = (newValue, fieldName) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue(fieldName, newValue);
    } else if (!newValue) {
      // If cleared manually, set to null
      setFieldValue(fieldName, null);
    }
  };
  return (
    <>
      <Form>
        <Grid
          container
          spacing={2}
          className="box_shadow_box"
          style={{ marginBottom: '20px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={12}>
            <div className="invoice_create_recycle">
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Invoice Details: {ticketDetails?.id}
              </Typography>
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Machine Id: {ticketDetails?.machine?.machineId}
              </Typography>
            </div>
          </Grid>

          <Grid xs={12} item sm={6}>
            <Grid container spacing={3} className="separate_border month_recycler_invoice">
              <Grid item xs={12} sm={12}>
                <Typography sx={{ textAlign: 'left', marginBottom: '-10px ' }}>Raised Month :</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{ ...theme.typography.customInput }}
                  className="form_monthrange"
                  // error={Boolean(touched.fromMonth && errors.fromMonth)}
                >
                  <OutlinedInput
                    id="outlined-adornment-email-machineId"
                    type="text"
                    value={moment(ticketDetails?.createdDate).format('MMMM YYYY')}
                    name="machine_id"
                    onBlur={handleBlur}
                    label=" Machine Id"
                    inputProps={{}}
                    autoComplete="off"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ textAlign: 'left', marginBottom: '7px ' }}>Select Due Date:</Typography>
            </Grid>

            <FormControl
              fullWidth
              sx={{ ...theme.typography.customInput }}
              className="form_monthrange"
              error={Boolean(touched.duedate && errors.duedate)}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    sx={{ ...theme.typography.customInput }}
                    label="Due Date"
                    name="duedate"
                    onError={handleBlur}
                    // views={['year', 'month']}
                    value={values.duedate ? dayjs(values.duedate) : null}
                    onChange={(newValue) => handleDateChange(newValue, 'duedate')}
                    // Disable past months by setting the minimum date to the current date
                    minDate={dayjs()}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          // ...params.InputProps,
                          readOnly: true // Disable manual typing
                        }}
                        error={Boolean(touched.duedate && errors.duedate)}
                        helperText={touched.duedate && errors.duedate}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
            {touched.duedate && errors.duedate && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-title"
                sx={{
                  fontSize: '13.8px',
                  fontWeight: 500
                }}
              >
                {errors.duedate}
              </FormHelperText>
            )}
          </Grid>

          <Grid></Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} style={{ marginBottom: '20px' }} className="address_detail_new">
          <Grid item xs={12} sm={6}>
            <Box>
              <div className="view_ticket_addressbx">
                <Typography variant="h5" component={'h5'}>
                  {/* ShredBox Pvt. Ltd. */}
                  Issued By :<Typography variant="h3">{userData?.recycler?.name}</Typography>
                </Typography>
                <Typography variant="h5" component={'h5'}>
                  Name: {userData?.prefix + ' ' + userData.firstName + ' ' + userData.lastName}
                </Typography>
                {/* <Typography variant="h5" component={'h5'}>
                  Address: {userData.address}
                </Typography> */}
                <Typography variant="h5" component={'h5'}>
                  Email: {userData.email}
                </Typography>
                <Typography variant="h5" component={'h5'}>
                  Phone: {userData.phone}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <div className="view_ticket_addressbx view_ticket_addressbx2 view_ticket_addressbx222">
                {isLoadingTicketDetails ? (
                  <>
                    <Typography variant="h5" component={'h5'}>
                      <Skeleton variant="h5" />
                      <Typography variant="h3">
                        <Skeleton variant="h5" />
                      </Typography>
                    </Typography>

                    <Typography variant="h5" component={'h5'}>
                      <Skeleton variant="h5" />
                    </Typography>

                    <Typography variant="h5" component={'h5'}>
                      <Skeleton variant="h5" />
                    </Typography>
                    <Typography variant="h5" component={'h5'}>
                      <Skeleton variant="h5" />
                    </Typography>
                  </>
                ) : (
                  <>
                    {ticketDetails?.retailer ? (
                      <>
                        <Typography variant="h5" component={'h5'}>
                          Billed To:
                          <Typography variant="h3">{ticketDetails?.retailer?.name ?? ''}</Typography>
                        </Typography>

                        <Typography variant="h5" component={'h5'}>
                          Name:{' '}
                          {ticketDetails?.retailer?.mainPOC?.prefix +
                            ' ' +
                            ticketDetails?.retailer?.mainPOC?.firstName +
                            ' ' +
                            ticketDetails?.retailer?.mainPOC?.lastName}
                        </Typography>

                        <Typography variant="h5" component={'h5'}>
                          Email: {ticketDetails?.retailer?.mainPOC?.email}
                        </Typography>
                        <Typography variant="h5" component={'h5'}>
                          Phone: {ticketDetails?.retailer?.mainPOC?.phone}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h5" component={'h5'}>
                          Billed To:
                          <Typography variant="h3">{ticketDetails?.enterprise?.name ?? ''}</Typography>
                        </Typography>

                        <Typography variant="h5" component={'h5'}>
                          Name:{' '}
                          {ticketDetails?.enterprise?.mainPOC?.prefix +
                            ' ' +
                            ticketDetails?.enterprise?.mainPOC?.firstName +
                            ' ' +
                            ticketDetails?.enterprise?.mainPOC?.lastName}
                        </Typography>

                        <Typography variant="h5" component={'h5'}>
                          Email: {ticketDetails?.enterprise?.mainPOC?.email}
                        </Typography>
                        <Typography variant="h5" component={'h5'}>
                          Phone: {ticketDetails?.enterprise?.mainPOC?.phone}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {ticketDetails?.retailer ? (
          <>
            <RetailerTable values={values} touched={touched} errors={errors} handleChange={handleChange} />
          </>
        ) : (
          <>
            <EnterpriseTable
              values={values}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              isLoadingOrganizationItemDetails={isLoadingOrganizationItemDetails}
              organizationItemsDetails={organizationItemsDetails}
            />
          </>
        )}
        <Grid item xs={12}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Box sx={{ display: 'inline-block' }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isLoadingInvoice}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    color: 'white',
                    minWidth: '200px',
                    margin: '0px auto'
                  }}
                >
                  {isLoadingInvoice ? (
                    <>
                      <CircularProgress
                        sx={{
                          color: 'white',
                          height: 20,
                          width: 20
                        }}
                      />
                    </>
                  ) : (
                    `${btnName} Invoice`
                  )}
                </Button>
              </AnimateButton>
            </Box>
          </Box>
        </Grid>
      </Form>
    </>
  );
};

export default RecyclerCreateForm;
