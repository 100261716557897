import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useProfileUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.users}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
            // localStorage.setItem('userData', JSON.stringify(res?.data?.user));
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getProfileDataById');
      queryClient.refetchQueries({ queryKey: ['getProfileDataById'], type: 'active' });
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
//
export const useGetProfileById = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  return useQuery({
    queryKey: ['getProfileDataById'],
    queryFn: async () => {
      return await axiosInstance
        // .get(`${endpoints.users}/details?id=${userData.id}`)
        .get(`${endpoints.users}/findById/${userData.id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

// export const useGetProfileById = ({id}) => {

//   return useQuery({
//     queryKey: ['getProfileDataById'],
//     queryFn: async () => {
//       return await axiosInstance

//         .get(`${endpoints.users}/findById/${id}`)
//         .then((res) => {

//           if (res?.status == 200) {
//             return res?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, e.response.data.e);
//         });
//     },
//     onSuccess: ({ data }) => {
//       return data;
//     },
//     // refetchInterval: 30000
//   });
// };
