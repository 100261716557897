import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { useNavigate } from 'react-router-dom';
import { endpoints } from 'endpoints/endpoints';

export const useEnterpriseAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.enterprises}/create`, data);
  return res;
};
export const useGetEnterpriseList = ({ limit, sortOrder, sortField, current, previous, first, last }) => {
  return useQuery({
    queryKey: ['getEnterpriseData', limit, sortOrder, sortField, current, previous, first, last],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.enterprises}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetEnterpriseAllList = () => {
  return useQuery({
    queryKey: ['getEnterpriseDataAllList'],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.enterprises}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// for Editing Organizations

export const useGetEnterpriseById = ({ id }) => {
  return useQuery({
    queryKey: ['getEnterpriseDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.enterprises}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//Submiting Edited Organisations to List

export const useGetEnterpriseEditedList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.enterprises}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getEnterpriseData');
      // queryClient.refetchQueries('getEnterpriseDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///Enterprise update Status

export const useEnterpriseStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.enterprises}/status/${data?.id}`, { enabled: data?.enabled })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            // setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries('getEnterpriseData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

////Enterprise User List

export const useGetUserEnterpriseList = ({ limit, sortOrder, sortField, current, previous, first, last, enterpriseId }) => {
  return useQuery({
    queryKey: ['getEnterpriseUserData', limit, sortOrder, sortField, current, previous, first, last, enterpriseId],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.users}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          enterprise: enterpriseId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log('Error to load user data from enterprise', e);
        });
    },
    staleTime: 0,
    // enabled: Boolean(limit) && Boolean(sortOrder),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//// user add

export const useEnterpriseUserAdd = async (data) => {
  try {
    const res = await axiosInstance.post(`${endpoints.users}/create`, data);
    // console.log('Add enterprice res', data, res);
    return res;
  } catch (error) {
    toastConfig.type = 'error';
    setToast(toastConfig, error?.response?.data?.error);
    // console.log('Error to add user', data, error);
  }
};
///User get By id

export const useGetEnterpriseUserById = ({ id }) => {
  return useQuery({
    queryKey: ['getEnterpriseUserDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.users}/findById/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////user Update

export const useGetEnterpriseUserUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('Incoming Data', data, data?.data, data?.id);
      return await axiosInstance
        .patch(`${endpoints.users}/${data?.id} `, data?.data) //http://localhost:5500/api/v1/users/1AUvcUGNMqamBG2Wjd5Q
        .then((res) => {
          // console.log('User Update Response', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          // console.log('Error to update user', error);
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getEnterpriseUserDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getEnterpriseUserData');
      // queryClient.refetchQueries('getDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useGetEnterpriseMachineLocations = (data) => {
  return useQuery({
    queryKey: ['getEnterpriseLoactionDetailsDataList', data?.recycler?.id, data?.recycler?.locationId, data?.enterpriseId],
    queryFn: async () => {
      return await axiosInstance
        .post(`/${endpoints.enterprises}/locations`, {
          recycler: {
            id: data?.recycler?.id,
            locationId: data?.recycler?.locationId
          },
          enterpriseId: data?.enterpriseId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(data?.recycler?.id) && Boolean(data?.recycler?.locationId) && Boolean(data?.enterpriseId),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Enterprise Machines

export const useEnterpriseMachines = ({ limit, sortOrder, sortField, current, previous, first, last, enterprise }) => {
  return useQuery({
    queryKey: ['getEnterpriseMachinesData', limit, sortOrder, sortField, current, previous, first, last, enterprise],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          enterprise: enterprise
        })
        .then((res) => {
          if (res?.status == 200) {
            // console.log('🚀 ~ .then ~ resmmmmmmmmm:', res);
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log('Error to load user data from enterprise', e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false,
    staleTime: 0,

    onSuccess: ({ data }) => {
      return data;
    }
  });
};
