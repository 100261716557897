import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import SuperAdminSettingsForm from './form';
import { useEffect } from 'react';
const SuperAdminSettings = () => {
  const [isLoading, setLoading] = useState(true);
  const SettingsSchema = Yup.object().shape({
    ranges: Yup.array().of(
      Yup.object().shape({
        min: Yup.number().required('Min value is required').positive().integer(),
        max: Yup.number().required('Max value is required').positive().integer().moreThan(Yup.ref('min'), 'Max must be greater than Min')
      })
    )
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="Settings" obj={{ title: 'Settings', title2: '', url: '' }} />
          <MainCard title="Settings">
            <Formik
              initialValues={{
                ranges: [{ min: '', max: '' }]
              }}
              validationSchema={SettingsSchema}
              onSubmit={async (value) => {
                const submitData = {
                  ranges: value?.ranges
                };
                console.log('🚀 ~ onSubmit={ ~ submitData:', submitData);
              }}
            >
              {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
                <>
                  <SuperAdminSettingsForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}

                    //   handleBlur={handleBlur}
                  />
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default SuperAdminSettings;
