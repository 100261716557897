import { Box, Button, FormHelperText, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Field, FieldArray, Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
const SuperAdminSettingsForm = ({
  values,
  touched,
  errors,
  handleChange

  // handleBlur, setFieldValue, handleSubmit
}) => {
  const exceptThisSymbolsValue = ['e', 'E', '+', '-', '#', '%', '^', '.'];
  return (
    <>
      <Form>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Rows per page
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                  This options will define the number of records showing in the each list including admin panels different roles like
                  Enterprise, Retailer and Recycler.
                </Typography>
                <Grid container className="settings-row-page">
                  <FieldArray name="ranges">
                    {({ push, remove }) => (
                      <>
                        {values.ranges.map((range, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,

                              my: 1
                            }}
                          >
                            <Field
                              onChange={handleChange}
                              as={TextField}
                              name={`ranges[${index}].min`}
                              //   placeholder="20"
                              //   label="20"
                              variant="outlined"
                              size="small"
                              error={touched.ranges?.[index]?.min && Boolean(errors.ranges?.[index]?.min)}
                              helperText={touched.ranges?.[index]?.min && errors.ranges?.[index]?.min}
                              type="number"
                              onKeyDown={(e) => {
                                exceptThisSymbolsValue.includes(e.key) && e.preventDefault();
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            <Field
                              onChange={handleChange}
                              as={TextField}
                              name={`ranges[${index}].max`}
                              //   label="5"
                              variant="outlined"
                              size="small"
                              error={touched.ranges?.[index]?.max && Boolean(errors.ranges?.[index]?.max)}
                              helperText={touched.ranges?.[index]?.max && errors.ranges?.[index]?.max}
                              type="number"
                              onKeyDown={(e) => {
                                exceptThisSymbolsValue.includes(e.key) && e.preventDefault();
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            <IconButton
                              onClick={() => remove(index)}
                              color="secondary"
                              disabled={values.ranges.length === 1} // Disable remove if only one range is present
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}

                        <IconButton
                          onClick={() => push({ min: '', max: '' })} // Add new empty range
                          color="primary"
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Box sx={{ display: 'inline-block' }}>
              <AnimateButton>
                <Button
                  disableElevation
                  // disabled={isLoadingInvoice}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    color: 'white',
                    minWidth: '200px',
                    margin: '0px auto'
                  }}
                >
                  Save
                  {/* {isLoadingInvoice ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `${btnName} Invoice`
                      )} */}
                </Button>
              </AnimateButton>
            </Box>
          </Box>
        </Grid>
      </Form>
    </>
  );
};

export default SuperAdminSettingsForm;
