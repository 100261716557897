import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import RecyclerCreateForm from './form';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useInvoiceAddByRecyclerForOrg } from 'hooks/useInvoiceHooks';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { setToast, toastConfig } from 'utils/commonUtil';
const TaxType = {
  PERCENTAGE: 'percentage',
  PRICE: 'price'
};

const DiscountType = {
  PERCENTAGE: 'percentage',
  PRICE: 'price'
};

const validationSchema = Yup.object().shape({
  // fromMonth: Yup.date().nullable().required('From Month is required').typeError('From Month is required'),
  // toMonth: Yup.date()
  //   .nullable()
  //   .required('To Month is required')
  //   .when('fromMonth', (fromMonth, schema) => {
  //     return fromMonth ? schema.min(fromMonth, 'To Month cannot be before From Month') : schema;
  //   })
  // .typeError('From Month is required'),
  // organization_id: Yup.string().required('Please select the organization!'),
  // machine_location: Yup.string().required('Please select the machine location!'),
  // rowsRetailer: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       description: Yup.string().required('Description is required !'),
  //       amount: Yup.number()
  //         .required('Amount is required')
  //         .min(0, 'Amount must be a positive value')
  //         .test('is-decimal', 'Amount must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
  //       creditDebit: Yup.string().required('Please select Credit/Debit')
  //     })
  //   )
  //   .when('organization_type', {
  //     is: 'Retailers',
  //     then: Yup.array().min(1, 'At least one retailer row is required for Retailers'),
  //     otherwise: Yup.array().notRequired()
  //   }),
  // rowsEnterprise: Yup.array()
  //   .of(
  //     Yup.object({
  //       item: Yup.string().required('Required'),
  //       unitPrice: Yup.number().required('Required'),
  //       quantity: Yup.number().required('Required'),
  //       amount: Yup.number()
  //     })
  //   )
  //   .when('organization_type', {
  //     is: 'Enterprises', // condition when organization_type is 'Enterprises'
  //     then: Yup.array().min(1, 'At least one enterprise row is required for Enterprises'),
  //     otherwise: Yup.array().notRequired()
  //   }),
  // Validation for Retailers
  rowsRetailer: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required('Description is required!'),
        amount: Yup.number()
          .required('Amount is required')
          .min(0, 'Amount must be a positive value')
          .test('is-decimal', 'Amount must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
        creditDebit: Yup.string().required('Please select Credit/Debit')
      })
    )
    .when('organization_type', {
      is: 'Retailer',
      then: Yup.array().min(1, 'At least one retailer row is required for Retailers'),
      otherwise: Yup.array().notRequired()
    }),

  // Validation for Enterprises

  rowsEnterprise: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required('Description is required!'),
        amount: Yup.number()
          .required('Amount is required')
          .min(0, 'Amount must be a positive value')
          .test('is-decimal', 'Amount must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
        creditDebit: Yup.string().required('Please select Credit/Debit')
      })
    )
    .when('organization_type', {
      is: 'Enterprise',
      then: Yup.array().min(1, 'At least one enterprise row is required for Enterprises'),
      otherwise: Yup.array().notRequired()
    }),

  // rowsEnterprise: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       description: Yup.string().required('Description is required!'),
  //       unitPrice: Yup.number()
  //         .required('Unit Price is required')
  //         .min(0, 'Unit Price cannot be negative')
  //         .test('is-decimal', 'Unit Price must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
  //       quantity: Yup.number().required('Quantity is required').min(0, 'Quantity must be at least 1'),
  //       amount: Yup.number()
  //         .required('Amount is required')
  //         .test('is-correct-amount', 'Amount must equal Unit Price * Quantity', function (value) {
  //           const { unitPrice, quantity } = this.parent;
  //           const calculatedAmount = parseFloat(unitPrice) * parseFloat(quantity);
  //           return value === calculatedAmount;
  //         })
  //     })
  //   )
  //   .when('organization_type', {
  //     is: 'Enterprises',
  //     then: Yup.array().min(1, 'At least one enterprise row is required for Enterprises'),
  //     otherwise: Yup.array().notRequired()
  //   }),
  tax: Yup.number()
    .required('Tax is required')
    .test('is-decimal', 'Tax can only have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
    .test('tax-check', 'Tax cannot exceed subtotal', function (value) {
      const { rowsRetailer, rowsEnterprise, taxType } = this.parent;

      // Calculate subtotal for both rowsRetailer and rowsEnterprise
      const subtotalRetailer = rowsRetailer.reduce((total, row) => {
        const amount = parseFloat(row.amount) || 0;
        return row.creditDebit === 'Credit' ? total + amount : total - amount;
      }, 0);

      const subtotalEnterprise = rowsEnterprise.reduce((total, row) => {
        const amount = parseFloat(row.unitPrice * row.quantity) || 0;
        return total + amount; // Sum amounts for rowsEnterprise
      }, 0);

      const subtotal = subtotalRetailer + subtotalEnterprise;

      // Calculate tax based on type
      if (taxType === TaxType.PERCENTAGE) {
        const taxAmount = (value / 100) * subtotal;
        return taxAmount <= subtotal;
      } else {
        return value <= subtotal;
      }
    })
    .test('is-decimal', 'Tax must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
  discount: Yup.number()
    .required('Discount is required')
    .test('is-decimal', 'Discount can only have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
    .test('discount-check', 'Discount cannot exceed subtotal', function (value) {
      const { rowsRetailer, rowsEnterprise, discountType } = this.parent;

      // Calculate subtotal for both rowsRetailer and rowsEnterprise
      const subtotalRetailer = rowsRetailer.reduce((total, row) => {
        const amount = parseFloat(row.amount) || 0;
        return row.creditDebit === 'Credit' ? total + amount : total - amount;
      }, 0);

      const subtotalEnterprise = rowsEnterprise.reduce((total, row) => {
        const amount = parseFloat(row.unitPrice * row.quantity) || 0;
        return total + amount; // Sum amounts for rowsEnterprise
      }, 0);

      const subtotal = subtotalRetailer + subtotalEnterprise;

      if (discountType === DiscountType.PERCENTAGE) {
        const discountAmount = (value / 100) * subtotal;
        return discountAmount <= subtotal;
      } else {
        return value <= subtotal;
      }
    })
    .test('is-decimal', 'Discount can only have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
  shippingAndHandlingCharge: Yup.number().test('is-decimal', 'Shipping & handling charge can only have up to 2 decimal places', (value) =>
    /^\d+(\.\d{1,2})?$/.test(value)
  ),
  groupByVal: Yup.string().when('organization_type', {
    is: 'Enterprises', // condition when organization_type is 'Enterprises'
    then: Yup.string().required('Group By Value is required for Enterprises'),
    otherwise: Yup.string().notRequired()
  })
});

const calculateSubtotal = (values) => {
  return values.rowsRetailer.reduce((total, row) => {
    const amount = parseFloat(row.amount) || 0;
    return row.creditDebit === 'Credit' ? total + amount : total - amount;
  }, 0);
};

const calculateSubtotalEnterprise = (data) => {
  return data?.rowsEnterprise?.reduce((total, row) => {
    const amount = parseFloat(row.amount) || 0;
    return row.creditDebit === 'Credit' ? total + amount : total - amount;
  }, 0);
};

// Function to calculate the total value including tax, discount, and shipping charge
const calculateTotalEnterprise = (data) => {
  // Safeguard against invalid input types
  const subtotal = calculateSubtotalEnterprise(data);

  const taxAmount = data?.taxType === TaxType.PERCENTAGE ? (data.tax / 100) * subtotal : parseFloat(data.tax) || 0;
  const discountAmount = data.discountType === DiscountType.PERCENTAGE ? (data.discount / 100) * subtotal : parseFloat(data.discount) || 0;
  const shippingAndHandlingCharge = data.shippingAndHandlingCharge || 0;
  // setFieldValue('totalValue', values);
  // setTotalValue(subtotal + taxAmount - discountAmount);
  return subtotal + taxAmount - discountAmount + shippingAndHandlingCharge;
};

const RecyclerCreateInvoice = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { mutate: addInvoiceByRcyclerForOrg, isPending: isLoadingInvoiceTicket } = useMutation({
    mutationFn: useInvoiceAddByRecyclerForOrg
  });
  let { id } = useParams();

  return (
    <>
      <MainCard title="Recycler Invoice">
        <Formik
          initialValues={{
            fromMonth: null, // Initial value (e.g., start of the year)
            toMonth: null, // Initial value (e.g., end of the year)
            rowsRetailer: [
              {
                id: 1,
                item: '1',
                description: `Invoice for Ticket ${id.slice(1)} raised on ${moment().format('Do YYYY')}`,
                amount: 0,
                creditDebit: 'Credit'
              }
            ],
            rowsEnterprise: [
              {
                id: 1,
                item: '1',
                description: `Invoice for Ticket ${id.slice(1)} raised on ${moment().format('Do MMMM')}`,
                amount: 0,
                creditDebit: 'Credit'
              }
            ],
            tax: 0,
            taxType: TaxType.PERCENTAGE,
            discount: 0,
            discountType: DiscountType.PERCENTAGE,
            organization_id: '',
            organization_type: '',
            organization_location: '',
            organization_location_name: '',
            totalValue: 0.0,
            orgName: '',
            orgMainPOCName: '',
            orgMainPOCEmail: '',
            orgMainPOCPhone: '',
            shippingAndHandlingCharge: 0,
            groupByVal: '',
            duedate: '',
            ticket_id: ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            const subtotal = calculateSubtotal(values);
            const taxAmount = values.taxType === TaxType.PERCENTAGE ? (values.tax / 100) * subtotal : parseFloat(values.tax) || 0;
            const discountAmount =
              values.discountType === DiscountType.PERCENTAGE ? (values.discount / 100) * subtotal : parseFloat(values.discount) || 0;
            let discountValue = Number(subtotal - discountAmount);
            let shippingAndHandlingCharge = values.shippingAndHandlingCharge || 0;

            let totalValue = Number(discountValue + taxAmount + shippingAndHandlingCharge).toFixed(2);

            let totalValueEnterpeise = calculateTotalEnterprise(values).toFixed(2);
            const itemsRetailer = [];
            const itemsEnterprise = [];
            // const formattedFromMonth = dayjs(values.fromMonth).format('MMMM YYYY');

            // const formattedToMonth = dayjs(values.toMonth).format('MMMM YYYY');
            const formattedDueDate = values?.duedate ? dayjs(values?.duedate).format('YYYY-MM-DD') : '';

            for (let index = 0; index < values?.rowsRetailer?.length; index++) {
              const element = values?.rowsRetailer[index];
              itemsRetailer.push({
                index: element?.id,
                description: element?.description,
                amount: Number(element?.amount).toFixed(2),
                type: element?.creditDebit === 'Credit' ? 'Credit' : 'Debit'
              });
            }
            for (let index = 0; index < values?.rowsEnterprise?.length; index++) {
              const element = values?.rowsEnterprise[index];
              itemsEnterprise.push({
                id: element?.id,

                description: element?.description,
                amount: Number(element?.amount).toFixed(2),
                type: element?.creditDebit === 'Credit' ? 'Credit' : 'Debit'
              });
            }

            const submitData = {
              ticket: id.slice(1),
              raisedBy: {
                location: '',
                locationName: '',
                type: 'Recycler',
                organization: {
                  id: userData?.recycler?.id,
                  name: userData?.recycler?.name,
                  mainPOC: {
                    name: userData?.prefix + ' ' + userData?.firstName + ' ' + userData?.lastName,
                    email: userData?.email,
                    phone: userData?.phone
                  }
                }
              },
              raisedTo: {
                // organization: values?.recycler_id,
                location: values?.organization_location,
                locationName: values?.organization_location_name,
                organization: {
                  id: values?.organization_id,
                  name: values?.orgName,
                  mainPOC: {
                    name: values?.orgMainPOCName,
                    email: values?.orgMainPOCEmail,
                    phone: values?.orgMainPOCPhone
                  }
                },
                type: values?.organization_type === 'Enterprise' ? 'Enterprise' : 'Retailer'
              },
              tax: {
                value: Number(values?.tax).toFixed(2),
                type: values?.taxType === 'percentage' ? 'Percentage' : 'Amount'
              },
              period: {
                from: moment().format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD')
              },
              discount: {
                value: Number(values?.discount).toFixed(2),
                type: values?.discountType === 'percentage' ? 'Percentage' : 'Amount'
              },
              items: values?.organization_type === 'Retailer' ? itemsRetailer : itemsEnterprise,
              totalAmount: values?.organization_type === 'Enterprise' ? totalValueEnterpeise : Number(totalValue).toFixed(2),
              shippingHandlingCharge: Number(values?.shippingAndHandlingCharge).toFixed(2),
              dueDate: formattedDueDate
            };
            // console.log('🚀 ~ Recycler Invoice Add ~ submitData:', submitData);
            addInvoiceByRcyclerForOrg(submitData, {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.success);
                  resetForm({});
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setValues, setTouched }) => (
            <RecyclerCreateForm
              btnName="Create"
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              isLoadingInvoice={isLoadingInvoiceTicket}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              //   id={''}
              setValues={setValues}
            />
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default RecyclerCreateInvoice;
