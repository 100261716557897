import { Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import UpdateRetailerOrganizationForm from './form';
import { useOrganizationUpdate } from 'hooks/useOrganizationUpdateHooks';
import { useGetRetailerById } from 'hooks/useRetailerHooks';

const UpdateRetailerOrganization = () => {
  const [isLoading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userData'));

  const { data: retailerDetails } = useGetRetailerById({
    id: userData.retailer?.id
  });
  const { mutate: updatedOrg, isPending: isLoadingOrgUpdate } = useOrganizationUpdate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage name="Organization" obj={{ title: 'Organization', title2: '', url: '' }} />
          <MainCard>
            <Formik
              initialValues={{
                RetailerName: '',

                RetailerWeb: ''
              }}
              validationSchema={Yup.object().shape({
                RetailerName: Yup.string()
                  .max(50, 'Retailer Name must be at most 50 characters')
                  .required('Retailer Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, 'Retailer Name should not be in any special character'),
                RetailerWeb: Yup.string().url('Please enter a valid Retailer Website Address')
              })}
              onSubmit={async (value) => {
                const data = {
                  organizationId: userData?.retailer?.id,
                  type: userData?.organizationType,
                  details: {
                    name: value?.RetailerName,
                    website: value?.RetailerWeb
                  }
                };

                updatedOrg(data);
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched }) => (
                <UpdateRetailerOrganizationForm
                  btnName="Update Organization"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingOrgUpdate={isLoadingOrgUpdate}
                  setFieldValue={setFieldValue}
                  // id={''}
                  setTouched={setTouched}
                  retailerDetails={retailerDetails}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default UpdateRetailerOrganization;
