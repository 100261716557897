// assets

import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

// import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import dashboard from './dashboard';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ReceiptIcon from '@mui/icons-material/Receipt';

// constant

const icons = {
  PrecisionManufacturingOutlinedIcon,

  GroupOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocationOnOutlinedIcon,
  LocalActivityIcon,
  ReceiptIcon
};

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'retailermachines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/retailer/machines'
    },
    {
      id: 'retailerjob',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/retailer/jobs'
    },
    // {
    //   id: 'authentication',
    //   title: 'Users',
    //   type: 'item',
    //   breadcrumbs: true,
    //   icon: icons.GroupOutlinedIcon,
    //   url: '/dashboard/retailer/user'
    // },
    {
      id: 'retailerLocations',
      title: 'Locations',

      type: 'item',
      breadcrumbs: true,
      icon: icons.LocationOnOutlinedIcon,
      url: '/dashboard/retailer/locations'
    },
    {
      id: 'retailertickets',
      title: 'Tickets',

      type: 'item',
      breadcrumbs: true,
      icon: icons.LocalActivityIcon,
      url: '/dashboard/retailer/tickets'
    },
    {
      id: 'existingInvoiceEnterprise',
      title: 'Existing Invoices',
      type: 'item',
      icon: icons.ReceiptIcon,
      breadcrumbs: true,
      url: '/dashboard/retailer/existing/invoices'
    }
  ]
};

const RetailerPage = {
  items: [dashboard, pages]
};
export default RetailerPage;
