import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';

export const useLocationAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.locations}/create`, data);
  return res;
};
//List Api

export const useGetLocationList = ({ limit, sortOrder, sortField, current, previous, first, last }) => {
  return useQuery({
    queryKey: ['getLocationData', limit, sortOrder, sortField, current, previous, first, last],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.locations}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount

    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//for Editing
export const useGetLocationId = ({ id }) => {
  return useQuery({
    queryKey: ['getLocationDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.locations}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Submitted Edited List

export const useLocationUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.locations}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getLocationDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
