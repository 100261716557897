/* eslint-disable react/prop-types */
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  // MenuItem,
  OutlinedInput,
  // Select,
  Typography,
  Box

  // Stack
} from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
// import { useGetRecycleList } from 'hooks/useRecycleHooks';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const EnterpriseUserForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  // isSubmitting,
  touched,
  values,
  isLoadingEnterprise,
  enterUserdetails,
  btnName,
  setFieldValue,
  id
  // id
  // userDetails
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (enterUserdetails) {
      setFieldValue('firstName', enterUserdetails?.firstName ? enterUserdetails?.firstName : '');
      setFieldValue('lastName', enterUserdetails?.lastName ? enterUserdetails?.lastName : '');
      setFieldValue('email', enterUserdetails?.email ? enterUserdetails?.email : '');
      setFieldValue('rfidCode', enterUserdetails?.rfidCode ? enterUserdetails?.rfidCode : '');
    }
  }, [setFieldValue, enterUserdetails]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              User Details
            </Typography>

            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.firstName && errors.firstName)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-firstName">First Name * </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-firstName"
                    type="text"
                    value={values.firstName}
                    name="firstName"
                    placeholder="e.g John"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="First Name * "
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-firstName"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.lastName && errors.lastName)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-last"> Last Name * </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-user-last"
                    type="text"
                    value={values.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Last Name *"
                    placeholder="e.g Doe"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-user-last"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-user-email">Email * </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-user-email"
                    type="text"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email *"
                    placeholder="e.g example@email.com"
                    inputProps={{}}
                    autoComplete="off"
                    disabled={Boolean(id)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-user-email"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.rfidCode && errors.rfidCode)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-rfidCode-login"> RFID Code</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-rfidCode-login"
                    type="text"
                    value={values.rfidCode}
                    name="rfidCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="RFID Code"
                    placeholder="e.g. 123"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.rfidCode && errors.rfidCode && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-rfidCode-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.rfidCode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isLoadingEnterprise}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingEnterprise ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `${btnName}  User`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default EnterpriseUserForm;
