import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CustomeMap from 'utils/CustomeMap';
import GoogleMaps from 'utils/google-place-api-autocomplete';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const EnterpriseLocationForm = ({
  errors,

  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,
  isLoadingLocation,
  setFieldValue,
  locationDetails,
  btnName,
  locationLatLng,
  setLocationLatLng
}) => {
  const theme = useTheme();
  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^'];
  useEffect(() => {
    if (locationDetails) {
      setFieldValue('location_name', locationDetails?.name);
      setFieldValue('location', locationDetails?.address?.fullAddress);
      setFieldValue('placeId', locationDetails?.address?.placeId);
      setFieldValue('latitude', locationDetails?.address?.coOrdinates?.latitude);
      setFieldValue('longitude', locationDetails?.address?.coOrdinates?.longitude);
      setFieldValue('tax', locationDetails?.tax);
      setLocationLatLng((prev) => {
        return {
          ...prev,
          lat: locationDetails?.address?.coOrdinates?.latitude,
          lng: locationDetails?.address?.coOrdinates?.longitude,
          label: locationDetails?.address?.fullAddress
        };
      });
    }
  }, [setFieldValue, locationDetails]);
  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Location Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.location_name && errors.location_name)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email-login"> Location Name *</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email-login"
                      type="text"
                      value={values.location_name}
                      name="location_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label=" Location Name *"
                      inputProps={{}}
                      autoComplete="off"
                      placeholder="Enter Your Location"
                    />
                    {touched.location_name && errors.location_name && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{
                          fontSize: '13.8px'
                        }}
                      >
                        {errors.location_name}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth error={Boolean(touched.location && errors.location)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-email-login"> Address *</InputLabel>
                    <GoogleMaps
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      error={Boolean(touched.location && errors.location)}
                      setLocationLatLng={setLocationLatLng}
                      locationDetails={locationDetails}
                    />
                    {touched.location && errors.location && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{
                          fontSize: '13.8px'
                        }}
                      >
                        {errors.location}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* tax value */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth error={Boolean(touched.tax && errors.tax)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-service-tax"> Tax % *</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-service-tax"
                      type="number"
                      value={values.tax}
                      name="tax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Tax % *"
                      placeholder="0.00"
                      autoComplete="off"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onKeyDown={(e) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                      }}
                    />
                    {touched.tax && errors.tax && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{
                          fontSize: '13.8px'
                        }}
                      >
                        {errors.tax}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <CustomeMap locationLatLng={locationLatLng} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingLocation}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingLocation ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Location`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default EnterpriseLocationForm;
